<template>
    <SplashScreen/>
    <router-view />
</template>

<script>
import { computed, reactive } from 'vue'
import {useHead } from '@vueuse/head'
import SplashScreen from './components/SplashScreen.vue';
export default {
  name: 'App',
  components: { SplashScreen },
  setup() {
    const siteData = reactive({
      title: `AI-Ökosystem | coeo Group`,
      description: `Interessieren Sie sich für weitere Informationen zu unserem Unternehmen oder unserem AI-Ökosystem? Nennen Sie uns gerne Ihre Kontaktdaten, und wir setzen uns anschließend mit Ihnen in Verbindung.`,
      keywords: `keywords`
    })
    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          meta:`description`,
          content: computed(() => siteData.description),
        },
        {
          meta:`keywords`,
          content: computed(() => siteData.keywords),
        }
      ],
      htmlAttrs: {
        lang: "de-DE",
      },
    })
  }
}
</script>