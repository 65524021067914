<template>
    <section class="splash-screen" ref="splashScreen">
        <svg width="200" height="63" viewBox="0 0 200 63" fill="none" class="logo">
            <path class="title" d="M176.793 16.1206H157.046C152.297 16.1206 148.423 19.9286 148.423 24.5551V39.8672H168.169C172.918 39.8672 176.792 36.0592 176.792 31.4328L176.793 16.1206ZM182 11V31.4413C182 38.9371 175.793 45 168.211 45H143.26V24.5587C143.26 17.0629 149.467 11 157.046 11H182Z" fill="#FEFFFF"/>
            <path class="title" d="M50.7811 16.1206V11H25.7886C18.2069 11 12 17.0629 12 24.5587V45H50.7401V39.8794H17.208V24.5599C17.208 19.9299 21.082 16.1206 25.8308 16.1206H50.7811Z" fill="#FEFFFF"/>
            <path class="title" fill-rule="evenodd" clip-rule="evenodd" d="M94.522 11V31.4413C94.522 38.9371 88.3139 45 80.7321 45H55.7806V24.5587C55.7806 17.0629 61.9874 11 69.5692 11H94.522ZM69.5729 16.1206H89.3264V31.4328C89.3264 36.0592 85.4512 39.8672 80.7011 39.8672H60.9463V24.5551C60.9463 19.9286 64.8216 16.1206 69.5729 16.1206Z" fill="#FEFFFF"/>
            <path class="title" d="M138.26 16.1206V11H113.265C105.684 11 99.478 17.0629 99.4793 24.5587V45H138.219V39.9206H104.685V30.5403L135.719 30.5804V25.4597L104.685 25.4196V24.5599C104.685 19.9299 108.56 16.1206 113.309 16.1206H138.26Z" fill="#FEFFFF"/>
        </svg>
    </section>
</template>
<script>
export default {
    mounted() {
        this.$refs.splashScreen.addEventListener('animationend', this.hideSplashScreen);
    },
    beforeUnmount() {
        this.$refs.splashScreen.removeEventListener('animationend', this.hideSplashScreen);
    },
    methods: {
        hideSplashScreen() {
            setTimeout(() => {
                this.$refs.splashScreen.style.display = 'none';
            }, 1800);
        }
    }
}
</script>

<style lang="scss">
   
    .splash-screen {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            background: var(--primary-color);
            height: 100vh;
            width: 100%;
            animation: lift 0.8s ease-in-out;
            animation-delay: 1.6s;
            animation-fill-mode: forwards;
    }
    .title {
        fill: #fff;
        animation: wave 0.4s, jump 1s;
        position: relative;
        top: 0;
        padding: 4px;
        transform: translate3d(0, 16%, 0);
        opacity: 0;
        z-index: 3;
        animation-fill-mode: forwards;

        &:nth-of-type(1) {
            animation: wave 0.4s, jump 1.1s ease-in-out alternate .05s;
        }

        &:nth-of-type(2) {
            animation: wave 0.4s, jump 1.1s ease-in-out alternate .1s;
        }

        &:nth-of-type(3) {
         animation: wave 0.4s, jump 1.1s ease-in-out alternate .15s;
        }

        &:nth-of-type(4) {
            animation: wave 0.4s, jump 1.1s ease-in-out alternate .2s;
        }
    }
    //animation 

    @keyframes wave {
        0% {
            top: 0%;
        }
        100% {
            top: 100%;
        }
    }

    @keyframes jump {
        0% {
            transform: translate3d(0, 0, 0);
            opacity: 0;
        }
        100% {
            transform: translate3d(0, -16%, 0);
            opacity: 1;
        }
    }

    @keyframes lift {
        0% {
            transform: translate3d(0, 0, 0);
            opacity: 1;
            visibility: visible;
        }
        100% {
            transform: translate3d(0, -100%, 0);
            opacity: 1;
            visibility: hidden;
        }
    }

    @keyframes appear {
        0% {
            visibility: hidden;
        }
        100% {
            visibility: visible;
        }
    }
</style>