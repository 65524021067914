import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
      path: '/',
      name: 'Home',
      component: () => import('@/views/HomeView.vue'),
    },
    {
      path: '/impressum',
      name: 'Impressum',
      component: () => import(/* webpackChunkName: "impressum" */ '@/views/ImpressumView.vue'),
    },
    {
      path: '/datenschutz',
      name: 'Datenschutz',
      component: () => import(/* webpackChunkName: "datenschutz" */ '@/views/DatenschutzView.vue'),
    },
  ]
  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
  })
export default router;